const DataServicios = {
    consulta: {
        h1: "Consulta",
        description: "Se realiza un 'Examen Físico General'  completo y sistemático de tu mascota desde la punta de su nariz hasta la punta de su cola. También se genera un expediente clínico para un adecuado seguimiento médico.",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20consulta%20m%C3%A9dica.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20consulta%20m%C3%A9dica."
    },
    vacunacion: {
        h1: "Vacunación",
        description: "Manejamos vacunas de calidad para proteger a tu mascota contra virus y bacterias. Llevamos un control preciso de vacunación a través de un carnet diseñado para este propósito. Generamos un expediente clínico de cada paciente para un adecuado seguimiento médico. El servicio ya incluye la consulta médica y un carnet con fotografía.",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20vacunación.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20vacunación."
    },
    desparasitasion: {
        h1: "Desparasitación Interna",
        description: "Usamos desparasitantes de funcionamiento efectivo contra gusanos planos y redondos que son los parásitos internos más comunes en perros y gatos. Generamos un expediente clínico de cada paciente para un adecuado seguimiento médico. El servicio ya incluye la consulta médica y un carnet con fotografía. ",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%desparasitación.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20desparasitación."
    },
    esterilizacion: {
        h1: "Esterilizacón",
        description: "Nuestro servicio médico de esterilización de perros y gatos, se realiza siguiendo protocolos médicos. Incluye:",
        descrpList: [
            "Preconsulta (revisión médica previa a la cirugía) y apertura de expediente.",
            "Cirugía con anestesia inhalada (inmovilización del paciente) para mayor seguridad de la mascota, analgesia (manejo del dolor) y staff médico: un médico responsable de realizar el procedimiento y un segundo médico a cargo de la anestesia.",
            "Una noche de hospitalización para observación.",
            "Retiro de puntos o una revisión inmediata sin costo.",
        ],
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20esterilización.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20esterilización."
    },
    cirugia: {
        h1: "Cirugía",
        description: "Contamos con cirugía general, en los casos de cirugía especializada trabajamos en alianza con otros hospitales para darte una atención completa. Incluye:",
        descrpList: [
            "Preconsulta (revisión médica previa a la cirugía) y apertura de expediente.",
            "Cirugía con anestesia inhalada (inmovilización del paciente) para mayor seguridad de la mascota, analgesia (manejo del dolor) y staff médico: un médico responsable de realizar el procedimiento y un segundo médico a cargo de la anestesia.",
            "Una noche de hospitalización para observación.",
            "Retiro de puntos o una revisión inmediata sin costo.",
        ],
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20cirugía.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20cirugía."
    },
    hospitalizacion: {
        h1: "Hospitalización",
        description: "Con este servicio su mascota estará recibiendo la atención médica necesaria para su recuperación mediante un monitoreo médico. El médico veterinario es quien determina si la hospitalización es necesaria a través de una inspección física.",
    },
    profilaxis: {
        h1: "Profilaxis Dental",
        description: "Contamos con equipo dental ultrasónico para retirar de manera efectiva el sarro dental de tu mascota, también utilizamos pulidor para un mejor terminado. Incluye:",
        descrpList: [
            "Preconsulta (revisión médica previa a la cirugía) y apertura de expediente.",
            "Procedimiento con anestesia inhalada (inmovilización del paciente) para mayor seguridad de la mascota, staff médico: un médico responsable de realizar el procedimiento y un segundo médico a cargo de la anestesia",
        ],
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20profilaxis%20dental",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20profilaxis%20dental."
    },
    estetica: {
        h1: "Estética",
        description: "Próximamente contaremos con el servicio de baño y corte realizado por personal capacitado. Aplicamos las medidas de seguridad necesarias para que en todo momento tu mascota esté bien.",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20consulta%20estética.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20una%20cita%20para%20estética."
    },
    pension: {
        h1: "Pensión",
        description: "Contamos con el servicio de pensión sólo para mascotas de talla pequeña y mediana (menores a 20 kg), es importante apartar sus fechas con anticipación.",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20pensión.",
        agendar: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20agendar%20las%20fechas%20de%20pensión%20de%20mi%20mascota."
    },
    accesorios: {
        h1: "Accesorios",
        description: "Contamos con una amplia gama de accesorios:",
        descrpList: [
            "Collar con diseños exclusivos, correa, extensión de correa, pechera, transportadoras, etc.",
            "Platos, Kit pasta dental y cepillo, refrescante oral, dispensadores de bolsas, etc.",
            "Juguetes con sonido y/o que crujen, peluches con catnip, interactivos, etc.",
            "Frazadas y camas.",
            "Ropa de temporada.",
            "Jabón, shampoo para pieles sensibles, anti-pulgas, de prescripción, espuma en seco.",
            "Desparasitantes externos confiables para combatir las infestaciones de pulgas, garrapatas y piojos en tus mascotas."
        ],
        descrpLegend: "En la tienda en línea podrás encontrar nuestros productos más vendidos, en la clínica se cuenta con una mayor gama y se atienden pedidos especiales.",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20accesorios.",
        tienda: "https://www.facebook.com/amikoosvet/shop/"
    },
    alimentos: {
        h1: "Alimentos y Premios",
        description: "Manejamos alimentos y premios de excelente calidad de la categorías:",
        descrpList: [
            "Premium: Nupec y Excellent.",
            "Super Premium: Royal Canin, Pro Plan y Hill´s.",
        ],
        descrpLegend: "También contamos con carnazas de sabores, diferentes tamaños y formas, así como premios de pollo e hígado deshidratado. En la tienda en línea podrás encontrar nuestros productos más vendidos, en la clínica se atienden pedidos especiales.",
        masInfo: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20alimentos%20y%20premios.",
        tienda: "https://www.facebook.com/amikoosvet/shop/Alimento-y-Premios-695923714358803/"
    }
}

export default DataServicios;